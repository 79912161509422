import { alertError, ExtendedButton, makeStyles } from "@placehires/react-component-library";
import React from "react";
import { useLoadingWrapper } from "../../hooks/loadingHooks";

type OauthButtonProps = {
  backgroundColor: string;
  providerName: string;
  iconSrc: string;
  color: string;
  onClick: () => Promise<void>;
};

const OauthButton: React.FC<OauthButtonProps> = ({
  backgroundColor,
  color,
  iconSrc,
  providerName,
  onClick
}) => {
  const { classes } = useStyles({ color });
  const [loadWrap, loading] = useLoadingWrapper();

  const handleClick = () => {
    return loadWrap(onClick).catch(() => alertError());
  };

  return (
    <ExtendedButton
      className={classes.button}
      classes={{
        startIcon: classes.startIcon
      }}
      startIcon={
        <img className={classes.icon} src={iconSrc} alt={`${providerName} Authenticate Icon`} />
      }
      loaderSize={26}
      fullWidth
      color="primary"
      variant="contained"
      loading={loading}
      onClick={handleClick}
      backgroundColor={backgroundColor}
    >
      <span className={classes.text}>Continue with {providerName}</span>
    </ExtendedButton>
  );
};

const useStyles = makeStyles<Partial<OauthButtonProps>>()((theme, props) => ({
  button: {
    marginBottom: theme.spacing(2),
    color: props.color,
    textTransform: "none",
    padding: "8px calc((100% - 195px)/2)",
    justifyContent: "flex-start"
  },
  icon: {
    height: 18,
    width: 18
  },
  startIcon: {
    marginLeft: 0,
    marginRight: theme.spacing(2)
  },
  text: {
    // paddingTop: 2
  }
}));

export default OauthButton;
