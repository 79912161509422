import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React from "react";

type EmailPasswordFormProps = {
  email: string;
  password: string;
  setEmail: (email: string) => void;
  setPassword: (password: string) => void;
  errorEmail?: boolean;
  errorPassword?: boolean;
  errorEmailText?: string;
  errorPasswordText?: string;
  business?: boolean;
  newPassword?: boolean;
};

const EmailPasswordForm: React.FC<EmailPasswordFormProps> = ({
  email,
  password,
  setEmail,
  setPassword,
  errorEmail,
  errorPassword,
  errorEmailText,
  errorPasswordText,
  business,
  newPassword
}) => {
  return (
    <>
      <Grid item xs={12}>
        <TextField
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          variant="outlined"
          required
          fullWidth
          label={business ? "Business Email Address" : "Email Address"}
          name="email"
          autoComplete="email"
          error={errorEmail}
          helperText={errorEmail && (errorEmailText || "Please provide a valid email")}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          variant="outlined"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          autoComplete={newPassword ? "new-password" : "current-password"}
          error={errorPassword}
          helperText={
            errorPassword &&
            (errorPasswordText || "Please provide a password with at least 6 characters")
          }
        />
      </Grid>
    </>
  );
};

export default EmailPasswordForm;
