import { Box } from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import React, { useEffect } from "react";
import { dividerWithContent } from "../../constants/styles";
import { handleSignInResult, loginWithGoogle } from "../../services/authService";
import { auth } from "../../services/firebaseService";
import { redirectOnLogin } from "../../utils/browserUtils";
import OauthButton from "./OauthButton";
import googleIcon from "../../assets/icons/google.svg";

const OAuthOptions: React.FC = () => {
  const { classes } = useStyles();

  useEffect(() => {
    auth.getRedirectResult().then(async (userCredentials) => {
      await handleSignInResult(userCredentials);
      await redirectOnLogin(userCredentials.user);
    });
  }, []);

  const onGoogleClick = () => {
    return loginWithGoogle();
  };

  return (
    <Box width="100%">
      <OauthButton
        backgroundColor="#ffffff"
        providerName="Google"
        iconSrc={googleIcon}
        color="#757575"
        onClick={onGoogleClick}
      />
      <div className={classes.divider}>
        <span>or</span>
      </div>
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  divider: {
    margin: theme.spacing(1, 0, 3),
    ...dividerWithContent(theme)
  }
}));

export default OAuthOptions;
