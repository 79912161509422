import Grid from "@mui/material/Grid";
import { ExtendedButton, makeStyles } from "@placehires/react-component-library";
import React, { useState } from "react";
import { useEmailPasswordAutofillRef } from "../../hooks/authHooks";
import { useLoadingWrapper } from "../../hooks/loadingHooks";
import { login } from "../../services/authService";
import { isValidEmail, isValidPassword } from "../../utils/validate";
import EmailPasswordForm from "./EmailPasswordForm";

type LoginFormProps = {
  business?: boolean;
};

const LoginForm: React.FC<LoginFormProps> = ({ business }) => {
  const { classes } = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorEmailText, setErrorEmailText] = useState(null);
  const [errorPasswordText, setErrorPasswordText] = useState(null);
  const formRef = useEmailPasswordAutofillRef(setEmail, setPassword);
  const [loadWrap, loading] = useLoadingWrapper();

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValidEmail(email)) {
      onEmailError();
    } else if (!isValidPassword(password)) {
      onPasswordError();
    } else {
      return loadWrap(() =>
        login(email, password).catch(({ code, message }) => {
          if (
            code == "auth/invalid-email" ||
            code == "auth/user-not-found" ||
            code == "auth/user-disabled"
          ) {
            onEmailError();
          } else if (code == "auth/wrong-password") {
            onPasswordError();
          } else {
            alert(message);
          }
        })
      );
    }
  };

  const onEmailError = () => {
    setErrorPassword(false);
    setErrorEmail(true);
    setErrorEmailText("Email does not exist");
  };

  const onPasswordError = () => {
    setErrorEmail(false);
    setErrorPassword(true);
    setErrorPasswordText("Incorrect password");
  };

  return (
    <form ref={formRef} className={classes.form} onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <EmailPasswordForm
          business={business}
          email={email}
          password={password}
          setEmail={setEmail}
          setPassword={setPassword}
          errorEmail={errorEmail}
          errorPassword={errorPassword}
          errorEmailText={errorEmailText}
          errorPasswordText={errorPasswordText}
        />
      </Grid>
      <ExtendedButton
        loaderSize={25}
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        loading={loading}
      >
        Log In
      </ExtendedButton>
    </form>
  );
};

const useStyles = makeStyles()((theme) => ({
  form: {
    width: "100%"
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export default LoginForm;
