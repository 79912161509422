import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Avatar, Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import { graphql, Link as GLink, useStaticQuery } from "gatsby";
import React from "react";
import LoginForm from "../../components/auth/LoginForm";
import OAuthOptions from "../../components/auth/OAuthOptions";
import { useAuthRedirect } from "../../hooks/authHooks";
import AuthLayout from "../../layout/AuthLayout";

const IMAGE_QUERY = graphql`
  query {
    file(relativePath: { eq: "signin.jpg" }) {
      ...fluidImage
    }
  }
`;

type LoginPageProps = {
  business?: boolean;
};

const LoginPage: React.FC<LoginPageProps> = ({ business }) => {
  const { classes } = useStyles({ business });
  const image = useStaticQuery(IMAGE_QUERY).file.childImageSharp.gatsbyImageData;
  const seoTitle = "Login as " + (business ? "Business" : "Applicant");
  useAuthRedirect();

  return (
    <AuthLayout image={image} seoTitle={seoTitle}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography variant="h4" className={classes.title}>
        Sign in to PlaceHires
      </Typography>
      {!business && <OAuthOptions />}
      <LoginForm business={business} />
      <Button
        color="primary"
        component={GLink}
        to="/login/forgot-password"
        className={classes.link}
      >
        Forgot password?
      </Button>
      <Box display="flex" alignItems="center">
        <Typography display="inline">
          Don't have an account yet?&nbsp;
          <GLink
            to={`/signup/${business ? "business" : "applicant"}`}
            color="primary"
            className={classes.link}
          >
            Sign Up
          </GLink>
        </Typography>
      </Box>
    </AuthLayout>
  );
};

const useStyles = makeStyles<LoginPageProps>()((theme, props) => ({
  title: {
    marginBottom: theme.spacing(props.business ? 3 : 2)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  link: {
    textDecoration: "none",
    fontSize: theme.typography.body1.fontSize,
    textTransform: "none",
    fontWeight: 500
  }
}));

export default LoginPage;
