import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@placehires/react-component-library";
import { IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import BackgroundImage from "../components/BackgroundImage";
import PublicHeader from "./PublicHeader";
import SEO from "./SEO";

export type AuthLayoutProps = {
  children: React.ReactNode;
  image: IGatsbyImageData;
  seoTitle?: string;
};

const AuthLayout: React.FC<AuthLayoutProps> = ({ children, image, seoTitle }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.outer}>
      <SEO title={seoTitle} />
      <PublicHeader hideXl />
      <Grid container component="main" className={classes.root}>
        <Grid
          item
          md={6}
          xl={7}
          component={BackgroundImage}
          image={image}
          loading="eager"
          alt="PlaceHires Login - Sign Up Image"
        />
        <Grid
          item
          xs={12}
          md={6}
          xl={5}
          component={Paper}
          elevation={6}
          square
          className={classes.paperWrapper}
        >
          <Container className={classes.paper} maxWidth="xs">
            {children}
          </Container>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles()({
  outer: {
    display: "flex",
    flexFlow: "column",
    position: "fixed",
    width: "100%",
    height: "100%"
  },
  root: {
    flex: 1,
    overflowY: "auto"
  },
  paperWrapper: {
    display: "flex",
    alignItems: "center"
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
});

export default AuthLayout;
